import React from "react";
import Hero from "../components/Hero";
import Header from "../components/Header";
import { Bg } from "../utils/Styles";

const Home = () => {
  return (
    <div>
      <Bg>
        <Header />
        <Hero />
      </Bg>
    </div>
  );
};

export default Home;
