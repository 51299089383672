import React from "react";
import styled from "styled-components";
import { logo } from "../utils/Images";
import { Link } from "react-router-dom";
import { IoIosMenu } from "react-icons/io";

const Header = () => {
  return (
    <div>
      <HeaderContainer>
        <div>
          <img src={logo} alt="" />
        </div>
        <div>
          <ul>
            <li>
              <Link to="#" className="active">
                Home
              </Link>
            </li>
            <li>
              <Link to="#">/</Link>
            </li>
            <li>
              <Link to="#" className="deactive">
                About Us
              </Link>
            </li>
            <li>
              <Link to="#">/</Link>
            </li>
            <li>
              <Link to="#" className="deactive">
                Services
              </Link>
            </li>
            <li>
              <Link to="#">/</Link>
            </li>
            <li>
              <Link to="#" className="deactive">
                About Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="d-flex">
          <div className="download">Download Brochure</div>
          <div className="menu d-flex">
            <IoIosMenu />
          </div>
        </div>
      </HeaderContainer>
      <Border></Border>
    </div>
  );
};

export default Header;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 22px 70px;

  img {
    width: 160px;
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      margin: 0px 15px;

      a {
        color: #ded7c9;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
      }
      .active {
        color: #ded7c9;
      }
      .deactive {
        color: #8f8a80;
      }
    }
  }
  .download {
    border: 2px solid #474945;
    color: #ded7c9;
    padding: 12px 25px;
    font-size: 14px;
    margin-right: 0.9rem;
    cursor: pointer;
  }
  .menu {
    background-color: #464845;
    color: #ded7c9;
    padding: 8px 25px;
    font-size: 25px;
    cursor: pointer;
  }

  @media only screen and (max-width: 991px) {
    padding: 22px 20px;
    ul {
      display: none;
    }
    .download {
      display: none;
    }
  }
`;

const Border = styled.div`
  width: 93%;
  height: 1px;
  background-color: #605e58;
  margin: auto;
`;
