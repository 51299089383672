import styled from "styled-components";
import { texture } from "../utils/Images";

export const Bg = styled.div`
  background-image: url(${texture});
  width: 100%;
  height: 116vh;

  @media only screen and (max-width: 991px) {
    height: 100%;
  }
`;
