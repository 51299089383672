import React from "react";
import styled from "styled-components";
import { aim, lines, waves, textureCard, plus, bgCard } from "../utils/Images";
import { FiArrowUpRight } from "../utils/Icons";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <HeroContainer>
        <div className="data">
          <h1>
            designed <img src={lines} alt="" className="lines" /> to
          </h1>
          <h1 className="d-flex">
            grow <img src={aim} alt="" className="aim" /> your
          </h1>
          <h1>business</h1>
        </div>
        <div className="card">
          <div className="card-grid">
            <div className="book-call">
              <div className="data-img ">
                <div className="j-start">
                  <img src={plus} alt="" />
                  <h1>1.5K</h1>
                </div>
                <p>
                  Thousand of companies across SEA trust us to manage businesses
                  to grow their brand online.
                </p>
              </div>
              <div className="border-card"></div>
              <h5 className="j-start">
                maximize your business <br /> potential
              </h5>
              <div className="j-start card-btn">
                <div className="get-started btn-style">get started</div>
                <div className="book-call btn-style">book a call</div>
              </div>
            </div>
            <div className="learn-more">
              <img src={bgCard} alt="" className="cardBg" />
              <div className="learn-data">
                <h4>Designed for</h4>
                <h4>business of all</h4>
                <h4>sizes.</h4>
                <p>
                  From early stage startups to industry leading global
                  corporations,Valscrypt is the best choice.
                </p>
                <Link to="#" className="j-start">
                  Learn More <FiArrowUpRight className="icon" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <img src={waves} alt="" className="waves" />
      </HeroContainer>
    </>
  );
};

export default Hero;

const HeroContainer = styled.div`
  position: relative;
  .data {
    text-align: center;
    padding: 22px 70px;
    text-transform: uppercase;

    h1 {
      font-size: 100px;
      font-weight: 800;
      line-height: 100px;
      font-family: "Travel-Next";

      &:nth-child(1),
      &:nth-child(2) {
        color: #eaddcd;
      }
      &:nth-child(3) {
        color: #1fad00;
      }
      .aim {
        margin: 0px 40px;
        width: 140px;
        height: 80px;
        border-radius: 5px;
      }
      .lines {
        width: 130px;
        height: 70px;
        border-radius: 5px;
      }
    }
    @media only screen and (max-width: 991px) {
      padding: 22px 20px;
      h1 {
        font-size: 45px;
        line-height: 45px;

        .aim,
        .lines {
          display: none;
        }
      }
    }
    @media only screen and (max-width: 400px) {
      h1 {
        font-size: 38px;
        line-height: 42px;
      }
    }
  }
  .waves {
    position: absolute;
    top: 67%;
    width: 100%;
    height: 70vh;
    z-index: 1;

    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
  .card {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-image: url(${textureCard});
    width: 80%;
    height: 50vh;
    z-index: 100000000;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);

    .card-grid {
      display: grid;
      grid-template-columns: 1fr 2fr;

      .book-call {
        .data-img {
          padding: 20px 35px;
        }
        img {
          width: 130px;
          height: 70px;
        }
        h1 {
          font-family: "Travel-Next";
          color: #fff;
          font-weight: 600;
          font-size: 40px;
          margin-left: 2rem;
        }
        p {
          margin-top: 1.2rem;
          color: #eaddcd;
          font-size: 17px;
        }
        .border-card {
          height: 1px;
          width: 100%;
          background-color: #474844;
        }
        h5 {
          padding: 20px 35px;
          padding-bottom: 15px;
          color: #eaddcd;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;
        }
        .card-btn {
          padding: 0px 35px;

          .btn-style {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 14px;
            padding: 13px 34px;
            cursor: pointer;
          }
          .get-started {
            background-color: #eaddcd;
            color: #000;
            margin-right: 0.65rem;
          }
          .book-call {
            border: 1px solid #474844;
            color: #eaddcd;
          }
        }
      }
      .learn-more {
        display: grid;
        grid-template-columns: 1fr 2fr;

        .cardBg {
          width: 100%;
          height: 50vh;
          object-fit: cover;
        }
        .learn-data {
          padding: 20px 45px;
          text-align: left;

          h4 {
            color: #eaddcd;
            font-family: "Travel-Next-Medium";
            font-size: 35px;
            line-height: 40px;
          }
          p {
            margin-top: 1.2rem;
            margin-bottom: 1.6rem;
            color: #eaddcd;
            line-height: 23px;
          }
          a {
            border-bottom: 1px solid #eaddcd;
            color: #eaddcd;
            font-weight: 600;
            font-size: 17px;
            padding-bottom: 0.2rem;
            width: 26%;

            .icon {
              margin-left: 0.3rem;
              color: #1fad00;
              font-size: 20px;
            }
          }
        }
      }

      @media only screen and (max-width: 991px) {
        grid-template-columns: 1fr;

        .book-call {
          .data-img {
            padding: 20px 30px;
          }
          h5 {
            padding: 20px 30px;
          }
          .card-btn {
            padding: 0px 30px;

            .btn-style {
              padding: 13px 30px;
            }
          }
        }
        .learn-more {
          grid-template-columns: 1fr;

          .learn-data {
            padding: 20px 30px;

            h4 {
              font-size: 30px;
            }
            a {
              width: 40%;
            }
          }
          .cardBg {
            display: none;
          }
        }
      }
      @media only screen and (max-width: 390px) {
        .book-call {
          .data-img {
            padding: 20px 20px;
          }
          h5 {
            padding: 20px 20px;
          }
          .card-btn {
            padding: 0px 20px;
          }
        }
        .learn-more {
          grid-template-columns: 1fr;

          .learn-data {
            padding: 20px 20px;

            h4 {
              font-size: 30px;
            }
            a {
              width: 40%;
            }
          }
          .cardBg {
            display: none;
          }
        }
      }
    }

    @media only screen and (max-width: 991px) {
      position: relative;
      width: 95%;
      height: 100%;
    }
  }
`;
